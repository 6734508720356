export const environment = {
  production: true,
  //apiUrl: 'http://127.0.0.1:8005/api/'
  // apiUrl: 'http://127.0.0.1:8080/api/'
  //apiUrl: 'http://192.168.1.19:8080/api/'
  //apiUrl: 'http://192.168.0.71:8888/api/'
  //apiUrl: 'http://sem2.aviconn.in:8005/api/'

  //  apiUrl: 'http://192.168.0.47:8006/api/'  //Yash-aviconn path
  // apiUrl: 'http://asem.aviconn.net:8006/api/'   // Server Path
  // apiUrl: 'http://192.168.1.19:8005/api/'

     apiUrl: 'https://asem1.aviconn.in:8005/api/'   //live webapp server path (test_project)
    //  apiUrl: 'https://asem1.aviconn.in:8005/api/'   //new server path (test_project)
    //  apiUrl: 'http://127.0.0.1:8000/api/' //local system path
   
};
