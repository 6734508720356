import { Component, OnInit } from '@angular/core';
import { Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { DataService } from '../services/data.service';
import { NgModule } from '@angular/core';

export interface DialogData {
     from_date: string;
     till_date: string;
     avgValue:string;
     energyConsumed:string;
}

@Component({
  selector: 'app-avg-data',
  templateUrl: './avg-data.component.html',
  styleUrls: ['./avg-data.component.css']
})

export class AvgDataComponent implements OnInit {
  avgDatavalue: any;
  totalDatavalue: any;
  noofDays: any;
  endDateDisabled: boolean = true; // Initially disable end date

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<AvgDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private dataService:DataService) { }

    siteId= localStorage.getItem('siteId');
    live_date = localStorage.getItem('site_live_date')
    dateParts = this.live_date.split('/');
    formattedDate = `${this.dateParts[0]}-${this.dateParts[1]}-${this.dateParts[2]}`;
    todayFormattedDate = getFormattedTodayDate();

    date = new FormControl(new Date());
    serializedDate = new FormControl((new Date()).toISOString().substring(0,10));

    avgDataForm = new FormGroup({
      startDate: new FormControl(''),
      endDate: new FormControl({ value: '', disabled: this.endDateDisabled }), // Disable end date initially
      avgvalue: new FormControl(''),
      totalvalue: new FormControl(''),
      noOfDays: new FormControl('')
    });

  ngOnInit() {
    if (this.data !== null){
      this.avgDataForm.setValue({
        startDate: this.data.from_date ? this.data.from_date:"",
        endDate: this.data.till_date?this.data.till_date:"", 
        avgvalue: this.data.avgValue?this.data.avgValue:"",
        energyConsumed:this.data.energyConsumed?this.data.energyConsumed:""
      });
    }
  }

  onSubmit(){
    let data = {"siteId":this.siteId,"from_date":this.avgDataForm.value.startDate,"till_date":this.avgDataForm.value.endDate,}
    this.dataService.avgDataValue(data).subscribe(
      response =>{
        console.log("response : ", response)
        this.avgDatavalue = response['value'];
        this.totalDatavalue = response['energyConsumed'];
        this.noofDays = response['totalDays']
      }
    )   
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  startDateSelected() {
    // Enable end date once start date is selected
    this.endDateDisabled = false;
    this.avgDataForm.get('endDate').enable();
  }
}

function getFormattedTodayDate(): string {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = today.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
}
