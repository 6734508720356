
import { Router } from '@angular/router';
import { CustomerDetailsModel } from './../models/user';

import { UserService } from './../services/user.service';
import { DataService } from './../services/data.service';
import { Component, OnInit, ViewChild } from '@angular/core';
//import { SimpleNotificationsComponent } from 'angular2-notifications';
//import { NotificationsService } from 'angular2-notifications';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import { from } from 'rxjs';
import * as Highcharts from 'highcharts';
import { AfterViewInit, ViewChildren, QueryList } from '@angular/core';


export interface AlarmListInterface1 {
  data:any;
 }
 export interface AlarmListInterface2 {
  data:any;
 }
 export interface AlarmListInterface3 {
  data:any;
 }


@Component({
  selector: 'app-alarm-history',
  templateUrl: './alarm-history.component.html',
  styleUrls: ['./alarm-history.component.css']
})

export class AlarmHistoryComponent  {
  // dataSource: Mat; //mandeep
  // dataSource;
  // dataSource1;
  // dataSource2;
  // dataSource3;
  tableData:any;
  data:any;
  myObj = JSON.parse(localStorage.getItem("account"));
  user_id = this.myObj["id"];
  user_type = this.myObj["UserType"];
  isShown:boolean = false;
  DGFuelLevel:boolean = false;
  DGOverTime:boolean = false;
  showHighLowPFAlarm:boolean = false;
  showAlarm:boolean = true;
  Highcharts = Highcharts;
  loading:boolean=false;
  total_alarm: any;
  medium_priority: any;
  high_priority: any;
  active_alarms: any;
  customer_home = false;
  super_admin_home = false;
  customer_name = false;
  first_time_alarm_detail = 0;
  constructor(private dataService:DataService, private userService:UserService, private router: Router) {
  }
   

   ngAfterViewInit() {
  }

  ngOnInit(){
    this.getCustomerAlarms();
    if (this.user_type == '1')
    {
      this.super_admin_home = true
      this.customer_home = false
      this.customer_name = true
   }
    else if(this.user_type == '4' || this.user_type == '5'){
      this.super_admin_home = false
      this.customer_home = true
    }
    
  }


  displayedColumns1 = ['serialNo','alarmType', 'priority','count'];
  displayedColumnsPFHighLowAlarm = ['SerialNo', 'AlarmName', 'PowerSource', 'Priority', 'Created', 'RPhase', 'YPhase', 'BPhase', 'Status']
  DGOverTimeColumn = ['SerialNo', 'AlarmName', 'Priority', 'Created', 'RunHours', 'Status']
  DGFuelLevelColumn = ['SerialNo', 'AlarmName', 'Priority', 'Created', 'FuelLevel', 'Status']

  // @ViewChild('paginator2') paginator2: MatPaginator;
  // @ViewChild('paginator3') paginator3: MatPaginator;
  // @ViewChild('initialpaginator') initialpaginator : MatPaginator;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  dataSource: MatTableDataSource<any>;
  dataSource1: MatTableDataSource<AlarmListInterface1>;
  dataSource2:MatTableDataSource<AlarmListInterface2>;
  dataSource3:MatTableDataSource<AlarmListInterface3>;
   applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    this.dataSource1.filter = filterValue;
  }
 

  home()
  {
    localStorage.removeItem('customer');
    location.reload();
  }


  getCustomerAlarms(){
    let data1 = {'siteId': localStorage.getItem('siteId')};
    console.log("siteId#########",data1)    
      this.userService.getAlarms(data1).subscribe(
      response => {
        let alarms=[];
        this.total_alarm = response['total_alarm']
        this.medium_priority = response['medium_priority']
        this.high_priority = response['high_priority']
        this.active_alarms = response['active_alarms']
        console.log("total_alarms : ",this.total_alarm)
        for (let i = 0; i <= response['data'].length-1; i++){
          let data = response['data'][i];
          let name = data['name']
          let priority = data["priority"]
          let count = data['count']
          let alarm_type = data['alarm_type']
          alarms.push({"name":name,"priority":priority,"count":count, "alarm_type": alarm_type})

        }
        this.dataSource = new MatTableDataSource(alarms);
        console.log(this.dataSource);
        this.dataSource.paginator = this.paginator.toArray()[0];  //mandeep
        console.log("ALarm Detail only %%%%%%%%")
        console.log("response data of alarms $$$$$$$$: ", response["data"])

      },
      
    );

  }


  getAlarmHistory(row){
    let data = {'alarm_type': row.alarm_type, 'siteId': localStorage.getItem('siteId')}
    this.userService.getAlarmsDetail(data).subscribe(
      response=>{
        console.log("response: ", response)
        if(response['status'] == 200){
          let alarm_details = []
          if(row.alarm_type == 0 || row.alarm_type == 1 || row.alarm_type == 2){
            this.DGFuelLevel=false;
            this.DGOverTime = false;
            this.showHighLowPFAlarm=true;
            for(let i=0; i<=response['data'].length - 1; i++){
              let resp = response['data'][i];
              let alarm_status = ''
              if(resp["is_active"] == true){alarm_status='Active'}
              else{alarm_status='InActive'}
              alarm_details.push({"alarm_name": resp['get_alarm_type_display'], "power_source": resp['power_source'],
                "priority": resp["get_alarm_priority_display"], "r_phase": resp["r_phase"], "y_phase": resp["y_phase"],
                "b_phase":resp["b_phase"], "status": alarm_status, "created": resp["created"]
              })
            }
            this.dataSource1 = new MatTableDataSource(alarm_details);
            
            this.dataSource1.paginator = this.paginator.toArray()[1];
            console.log("alarm high voltage paginan", this.paginator.toArray()[1]);
            
            console.log("@@@: ", this.dataSource1.paginator)
          }
          else if(row.alarm_type == 3){
            this.DGFuelLevel=false;
            this.DGOverTime = true;
            this.showHighLowPFAlarm=false;
            let alarm_details = []
            for(let i=0; i<=response['data'].length - 1; i++){
              let resp = response['data'][i];
              let alarm_status = ''
              if(resp["is_active"] == true){alarm_status='Active'}
              else{alarm_status='InActive'}
              alarm_details.push({"alarm_name": resp['get_alarm_type_display'], "priority": resp["get_alarm_priority_display"],
                "created": resp["created"], "run_hours": resp["run_hours"], "status": alarm_status
              })
            }
            this.dataSource2 = new MatTableDataSource(alarm_details);
            this.dataSource2.paginator = this.paginator.toArray()[1]
            console.log("alarm DG overTime voltage pagination", this.paginator.toArray()[1]);
            console.log("@@@: ", this.dataSource2.paginator);
          }
          else if(row.alarm_type == 4){
            let alarm_details = []
            this.DGOverTime = false;
            this.showHighLowPFAlarm=false;
            this.DGFuelLevel=true;
            for(let i=0; i<=response['data'].length - 1; i++){
              let resp = response['data'][i];
              let alarm_status = ''
              if(resp["is_active"] == true){alarm_status='Active'}
              else{alarm_status='InActive'}
              alarm_details.push({"alarm_name": resp['get_alarm_type_display'], "priority": resp["get_alarm_priority_display"],
                "created": resp["created"], "fuel_level": resp["fuel_level"], "status": alarm_status
              })
            }
            this.dataSource3 = new MatTableDataSource(alarm_details);
            this.dataSource3.paginator = this.paginator.toArray()[1];
            console.log("alarm DG Fuel leve voltage pagination", this.paginator.toArray()[1]);
            console.log("@@@: ", this.dataSource3.paginator)

          }
          
        }
        console.log("paginator: ", this.paginator.toArray(), this.paginator.length)
      }
    )
  }

  getParticularAlarm(row){
    this.first_time_alarm_detail += 1;
    this.getAlarmHistory(row);
  }

}





