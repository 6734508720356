// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  //apiUrl: 'http://127.0.0.1:8005/api/'
  // apiUrl: 'http://127.0.0.1:8080/api/'
  //apiUrl: 'http://192.168.1.19:8080/api/'
  //apiUrl: 'http://192.168.0.71:8888/api/'
  //apiUrl: 'http://sem2.aviconn.in:8005/api/'

  // apiUrl: 'http://192.168.0.22:8005/api/'
    //  apiUrl: 'http://192.168.0.47:8006/api/'  //Yash-aviconn path

  // apiUrl: 'http://206.189.143.24:8005/api/'   //new server path (test_project)
  // apiUrl: 'http://asem1.aviconn.net:8000/api/'   // provisionTest server path
    // apiUrl: 'http://127.0.0.1:8000/api/'    // Shahid local Server Path


          apiUrl: 'https://asem1.aviconn.in:8005/api/'   //new server path (test_project)
      //  apiUrl: 'https://asem1.aviconn.in:8005/api/'   //new server path (test_project)
        // apiUrl: 'http://127.0.0.1:8000/api/' //local system path
      //  apiUrl: 'http://192.168.1.19:8005/api/'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
