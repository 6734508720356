import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { Inject} from '@angular/core';
import { DataService } from '../services/data.service';
export interface DialogData {

  from_date: string;
  till_date: string;
 
}

@Component({
  selector: 'app-excelsheet',
  templateUrl: './excelsheet.component.html',
  styleUrls: ['./excelsheet.component.css']
})
export class ExcelsheetComponent implements OnInit {
  myObj = JSON.parse(localStorage.getItem("account"));
  user_id = this.myObj["id"];
  user_type = this.myObj["UserType"];
  endDateDisabled: boolean = true; // Initially disable end date

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<ExcelsheetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private dataService:DataService,) { }

    siteId= localStorage.getItem('siteId');
    live_date = localStorage.getItem('site_live_date')
    dateParts = this.live_date.split('/');
    formattedDate = `${this.dateParts[0]}-${this.dateParts[1]}-${this.dateParts[2]}`;
    todayFormattedDate = getFormattedTodayDate();
    date = new FormControl(new Date());
    serializedDate = new FormControl((new Date()).toISOString().substring(0,10));
    
      excelDataForm = new FormGroup({
      startDate: new FormControl(''),
      endDate: new FormControl(''),

    });

  ngOnInit() {
  
    if (this.data !== null){
      this.excelDataForm.setValue({
        startDate: this.data.from_date ? this.data.from_date:"",
        endDate: this.data.till_date?this.data.till_date:"", 
      
      });
    }


  }

    
  onSubmit(){
    let data = {"user_id":this.user_id,"user_type":this.user_type,"site_id":this.siteId,"from_date":this.excelDataForm.value.startDate,"end_date":this.excelDataForm.value.endDate,}
     console.log("function called", this.excelDataForm.value);
    this.dataService.showLoader();
    this.dataService.excelDataValue(data).subscribe(
      (response:any) =>{
        console.log("response : ", response)
        let date = new Date().getDate().toString()+'-'+ new Date().toLocaleDateString("en-US", { month: 'short' })  + '-' + new Date().getFullYear().toString();
        console.log("date : ",date)
        let blob:Blob=response.body as Blob;
        var downloadURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = ("energy_data"+date+ ".csv")
        link.click();
        this.dialogRef.close();
        this.dataService.hideLoader()
        
        
      }
    )
    
    // this.dialogRef.close();
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  startDateSelected() {
    // Enable end date once start date is selected
    this.endDateDisabled = false;
    this.excelDataForm.get('endDate').enable();
  }
}

function getFormattedTodayDate(): string {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = today.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
}




