import { ModalService } from './services/modalService';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { GlobalService } from './services/global.service';
import { MatDialogModule } from '@angular/material/dialog';
import { PopupModule } from 'ng2-opd-popup';
import { DialogOverComponent } from './dialog-over/dialog-over.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule, MatSidenavModule, MatListModule, MatPaginatorModule, MatSortModule, MatGridListModule, MatNativeDateModule, MatSelectModule } from '@angular/material';
import { MatTableModule }  from '@angular/material/table';
import { SuperAdminComponent } from './super-admin/super-admin.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import {MatRadioModule} from '@angular/material/radio';
import {MatCardModule} from '@angular/material/card';
import {    NgxGaugeModule,} from 'ngx-gauge';


/* chart library */ 
//import Highchart in angular
import { ChartModule } from 'angular-highcharts';
import { HighchartsChartModule } from 'highcharts-angular';

//import angular fusioncharts charts
import { FusionChartsModule } from 'angular-fusioncharts';

//import angular fusion library and chart modules
import * as FusionCharts from 'fusioncharts';
import * as Charts from 'fusioncharts/fusioncharts.charts';

import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { ComingDashComponent } from './coming-dash/coming-dash.component';
import { OutputGraphComponent } from './output-graph/output-graph.component';
// import { PieGraphComponent } from './pie-graph/pie-graph.component';
// import { BarchartComponent } from './barchart/barchart.component';
import { CustomerDashboardComponent } from './customer-dashboard/customer-dashboard.component';
import { WarehouseComponent } from './warehouse/warehouse.component';
// import { TestComponent } from './test/test.component';
import { WhMeteringComponent } from './wh-metering/wh-metering.component';
import { BaselineComponent } from './baseline/baseline.component';
import { DialogSwitchdashComponent } from './dialog-switchdash/dialog-switchdash.component';
import {MatSnackBarModule,MatSnackBarConfig,MatSnackBarHorizontalPosition,MatSnackBarVerticalPosition,} from '@angular/material';
import { AdminComponent } from './admin/admin.component';
import { AlarmComponent } from './alarm/alarm.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as solidGauge from 'highcharts/modules/solid-gauge.src';
import { FirePumpAlarmComponent } from './fire-pump-alarm/fire-pump-alarm.component';
import { FemsComponent } from './fems/fems.component';
import { AddDeviceDialogComponent } from './add-device-dialog/add-device-dialog.component';
import { FemsDialogComponent } from './fems-dialog/fems-dialog.component';
import { AddDevtypeDialogComponent } from './add-devtype-dialog/add-devtype-dialog.component';
import { LightsWattDataComponent } from './lights-watt-data/lights-watt-data.component';
import { FanswattdataComponent } from './fanswattdata/fanswattdata.component';
import { DeviceDetailsFemsComponent } from './device-details-fems/device-details-fems.component';
import { ExpiredDeviceDetailsFemsComponent } from './expired-device-details-fems/expired-device-details-fems.component';
import { AvgDataComponent } from './avg-data/avg-data.component';
import { ExcelsheetComponent } from './excelsheet/excelsheet.component';
import { LoadGraphComponent } from './load-graph/load-graph.component';
import { PfTableComponent } from './pf-table/pf-table.component';
import { LoadDataTableComponent } from './load-data-table/load-data-table.component';
import { CustomDateRangePickerComponent } from './custom-date-range-picker/custom-date-range-picker.component';
import { DgFuelExcelExportComponent } from './dg-fuel-excel-export/dg-fuel-excel-export.component';
import { SubmeteringComponent } from './submetering/submetering.component';
import { AlarmHistoryComponent } from './alarm-history/alarm-history.component';



//pass the fusioncharts library and chart modules
FusionChartsModule.fcRoot(FusionCharts, Charts, FusionTheme);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DialogOverComponent,
    DashboardComponent,
    SuperAdminComponent,
    ComingDashComponent,
    OutputGraphComponent,
    //PieGraphComponent,
    //BarchartComponent,
    CustomerDashboardComponent,
    WarehouseComponent,
    //TestComponent,
    WhMeteringComponent,
    BaselineComponent,
    DialogSwitchdashComponent,
    AdminComponent,
    AlarmComponent,
    
    
    FirePumpAlarmComponent,
    
    FemsComponent,
    
    AddDeviceDialogComponent,
    
    FemsDialogComponent,
    
    AddDevtypeDialogComponent,
    
    LightsWattDataComponent,
    
    FanswattdataComponent,
    
    DeviceDetailsFemsComponent,
    
    ExpiredDeviceDetailsFemsComponent,
    
    AvgDataComponent,
    
    ExcelsheetComponent,
    
    LoadGraphComponent,
    
    PfTableComponent,
    
    LoadDataTableComponent,
    
    CustomDateRangePickerComponent,
    
    DgFuelExcelExportComponent,
    
    SubmeteringComponent,
    
    AlarmHistoryComponent,  
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    NgxGaugeModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatDialogModule,  
    FusionChartsModule,
    MatPaginatorModule, 
    MatProgressSpinnerModule,
    MatTableModule,
    PopupModule.forRoot(),
    MatSortModule,
    MatMenuModule,
    ChartModule,
    HighchartsChartModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatSnackBarModule,
    MatRadioModule,
    MatCardModule,
    MatSlideToggleModule,
    
  ],
  exports: [MatDialogModule
  ],
  entryComponents: [
    DialogOverComponent,
    DialogSwitchdashComponent,
    FemsDialogComponent,
    AddDeviceDialogComponent,
    AddDevtypeDialogComponent,
    LightsWattDataComponent,
    FanswattdataComponent,
    DeviceDetailsFemsComponent,
    ExpiredDeviceDetailsFemsComponent,
    AvgDataComponent,
    ExcelsheetComponent,
    PfTableComponent,
    LoadDataTableComponent,
    CustomDateRangePickerComponent,
    DgFuelExcelExportComponent
    
  
    
  ],
  providers: [
    GlobalService,
    ModalService,
    [{ provide: HIGHCHARTS_MODULES, useFactory: () => [more, solidGauge] }],
    //PieGraphComponent
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
