import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  public isLoading: boolean = false;
  public isModalLoading: boolean = false;

  constructor() { }

  showLoader(isModal: boolean = false) {
    if (isModal) {
      this.isModalLoading = true;
      console.log("&&&&&&&&&&&&&&&&&&&&&77777True")
    } else {
      this.isLoading = true;
      console.log("*************************88877777True")

    }
  }

  hideLoader(isModal: boolean = false) {
    if (isModal) {
      this.isModalLoading = false;
    } else {
      this.isLoading = false;
    }
  }

}
